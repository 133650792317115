import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Header from '../components/Header';

import {
  SectionComponent,
  HeadlineComponent,
  ParagraphComponent,
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';

const GridComponent = styled.div`
  margin: 15px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 15px;
  justify-content: space-between;
  justify-items: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
  }
`;

const Container = styled.div`
  margin-bottom: 4rem;
`;

function PartnersPage() {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "gallery-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      galleryData: allContentfulGallery(sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            date(formatString: "MMMM Do, YYYY")
            gallery {
              fixed(width: 300, height: 300, quality: 100) {
                ...GatsbyContentfulFixed
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Gallery"
        link="https://teampoker.com/gallery"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <Header image={data.background.childImageSharp.fluid} text="GALLERY" />
      <SectionComponent mg="1.5rem">
        {data.galleryData.edges.map((edge, idx) => {
          return (
            <Container key={edge.node.title}>
              <HeadlineComponent>{edge.node.title}</HeadlineComponent>
              <ParagraphComponent>{edge.node.date}</ParagraphComponent>
              <GridComponent>
                {edge.node.gallery.map(image => (
                  <Img key={image.fixed.src} fixed={image.fixed} />
                ))}
              </GridComponent>
            </Container>
          );
        })}
      </SectionComponent>
    </Layout>
  );
}

export default PartnersPage;
